import React, { useState, useCallback } from 'react';
import { Button } from 'reactstrap';
import { BsDownload, BsZoomIn, BsZoomOut, BsArrowDownCircle, BsArrowUpCircle, BsSearch } from 'react-icons/bs';

import DocumentSearchbar from './DocumentSearchbar';

const MAX_PAGES = 20;
const MOBILE_BREAKPOINT = 768;
const MIN_DISPLAY_WIDTH = 300;
const ZOOM_IN_RATE = 1.25;
const ZOOM_OUT_RATE = 0.75;

export interface PdfToolbarProps {
  isPdf?: boolean;
  toolbarTop: number;
  toolbarHeight: number;
  searchBarOffsetX: number;
  windowWidth: number;
  containerWidth: number;
  pdfPageWidth: number;
  zoom: number;
  numPages: number;
  startPage: number;
  currentPage: number;
  currentMatchIndex: number;
  totalKeywordMatches: number;
  downloadEnabled: boolean;
  keywords: string;
  onDownload: any;
  onZoomChanged: any;
  onStartPageChanged: any;
  onKeywordsChanged: any;
  onMatchIndexChanged: any;
  onCurrentPageChanged: any;
}

const DocumentToolbar = (props: PdfToolbarProps) => {
  const {
    isPdf,
    toolbarTop,
    toolbarHeight,
    searchBarOffsetX,
    windowWidth,
    containerWidth,
    pdfPageWidth,
    zoom,
    numPages,
    startPage,
    currentPage,
    currentMatchIndex,
    totalKeywordMatches,
    downloadEnabled,
    keywords,
    onDownload,
    onZoomChanged,
    onStartPageChanged,
    onKeywordsChanged,
    onMatchIndexChanged,
    onCurrentPageChanged,
  } = props;

  const [showSearchbar, setShowSearchbar] = useState<boolean>(false);

  const handlePageChanged = (event: any) => {
    onCurrentPageChanged(Number(event.target.value));
  };

  const handlePageUp = () => {
    onCurrentPageChanged(Math.max(startPage, currentPage - 1));
  };

  const handlePageDown = () => {
    onCurrentPageChanged(Math.min(startPage + MAX_PAGES - 1, numPages, currentPage + 1));
  };

  const onPreviousPageChunk = () => {
    onStartPageChanged(Math.max(1, startPage - MAX_PAGES));
  };

  const onNextPageChunk = () => {
    onStartPageChanged(Math.min(numPages, startPage + MAX_PAGES));
  };

  const prevPageChunkButton = isPdf && startPage > 1 && (
    <Button
      className={'mr-2 btn-sm'}
      color={'primary'}
      onClick={onPreviousPageChunk}
      title={`Retrieve, view and search pages ${Math.max(1, startPage - MAX_PAGES)} to ${Math.min(numPages, startPage - 1)}`}
    >
      {`${windowWidth >= MOBILE_BREAKPOINT ? 'View pages ' : ''}${Math.max(1, startPage - MAX_PAGES)} to ${Math.min(
        numPages,
        startPage - 1,
      )}`}
    </Button>
  );

  const nextPageChunkButton = isPdf && startPage + MAX_PAGES - 1 < numPages && (
    <Button
      className={'ml-2 btn-sm'}
      color={'primary'}
      onClick={onNextPageChunk}
      title={`Retrieve, view and search pages ${startPage + MAX_PAGES} to ${Math.min(numPages, startPage + MAX_PAGES * 2 - 1)}`}
    >
      {startPage + MAX_PAGES < numPages
        ? `${windowWidth >= MOBILE_BREAKPOINT ? 'View pages ' : ''}${startPage + MAX_PAGES} to ${Math.min(
            numPages,
            startPage + MAX_PAGES * 2 - 1,
          )}`
        : `${windowWidth >= MOBILE_BREAKPOINT ? 'View page ' : ''}${startPage + MAX_PAGES}`}
    </Button>
  );

  const handleToggleSearchbar = useCallback(() => {
    setShowSearchbar(!showSearchbar);
    onKeywordsChanged('');
  }, [showSearchbar, onKeywordsChanged]);

  const handleZoomIn = useCallback(() => {
    const newWidth = Math.min(zoom * ZOOM_IN_RATE * pdfPageWidth, containerWidth);
    onZoomChanged(Math.floor((newWidth * 100) / pdfPageWidth) / 100);
  }, [zoom, pdfPageWidth, containerWidth, onZoomChanged]);

  const handleZoomOut = useCallback(() => {
    const newWidth = Math.max(zoom * ZOOM_OUT_RATE * pdfPageWidth, MIN_DISPLAY_WIDTH);
    onZoomChanged(Math.floor((newWidth * 100) / pdfPageWidth) / 100);
  }, [zoom, pdfPageWidth, onZoomChanged]);

  // if (!doRender) return null;

  const viewingPages = isPdf && (
    <span>
      {prevPageChunkButton}
      <Button outline onClick={handlePageUp} disabled={currentPage < 2} title="Previous page" className="p-0 mx-2 border-0">
        <BsArrowUpCircle size="2rem" />
      </Button>
      |
      <Button
        outline
        onClick={handlePageDown}
        disabled={currentPage >= numPages}
        title="Next page"
        className="p-0 mx-2 border-0"
      >
        <BsArrowDownCircle size="2rem" />
      </Button>
      <input
        type="number"
        min={startPage}
        max={Math.min(numPages, startPage + MAX_PAGES - 1)}
        value={currentPage}
        className="mr-1"
        onChange={handlePageChanged}
        style={{ width: '3rem' }}
      />{' '}
      of {numPages}
      {nextPageChunkButton}
    </span>
  );

  return (
    <>
      <div className="d-flex justify-content-between pt-2 px-3 border bg-light">
        <span>
          {isPdf && (
            <span onClick={handleToggleSearchbar} style={{ cursor: 'pointer' }}>
              <Button
                outline
                title={`${showSearchbar ? 'Hide' : 'Show'} searchbar`}
                className={`p-0 border-0 ${showSearchbar ? 'active' : ''}`}
              >
                <BsSearch size="2rem" />
              </Button>
              <span className="ml-2 d-none d-sm-inline d-md-none d-lg-inline d-xl-inline">Search</span>
            </span>
          )}
        </span>
        <span>
          {isPdf && viewingPages}
          {isPdf && windowWidth >= MOBILE_BREAKPOINT && (
            <>
              <Button
                outline
                onClick={handleZoomOut}
                title="Zoom out"
                className="p-0 ml-3 border-0"
                disabled={zoom * ZOOM_OUT_RATE * pdfPageWidth < MIN_DISPLAY_WIDTH}
              >
                <BsZoomOut size="2rem" />
              </Button>
              &nbsp;|&nbsp;
              <Button
                outline
                onClick={handleZoomIn}
                title="Zoom in"
                className="p-0 border-0"
                disabled={zoom * pdfPageWidth >= containerWidth - 10}
              >
                <BsZoomIn size="2rem" />
              </Button>
            </>
          )}
          {!isPdf && '&nbsp;'}
        </span>
        <span>
          {downloadEnabled ? (
            <span onClick={onDownload} style={{ cursor: 'pointer' }}>
              <span className="mr-2 d-none d-sm-inline d-md-none d-lg-inline d-xl-inline">Download</span>
              <Button
                outline
                title={`Download ${windowWidth < MOBILE_BREAKPOINT ? 'desktop version' : ''}`}
                className="p-0 border-0"
              >
                <BsDownload size="2rem" />
              </Button>
            </span>
          ) : (
            <span>&nbsp;</span>
          )}
        </span>
      </div>
      {isPdf && showSearchbar && (
        <div
          className="position-fixed pt-1 px-2 border border-dark bg-light"
          style={{
            top: toolbarTop + toolbarHeight - 5,
            left: searchBarOffsetX + 15,
            zIndex: 3,
            borderRadius: 5,
            boxShadow: '5px 5px 5px grey',
          }}
        >
          <DocumentSearchbar
            numPages={numPages}
            maxPages={MAX_PAGES}
            startPage={startPage}
            keywords={keywords}
            totalMatches={totalKeywordMatches}
            currentMatchIndex={currentMatchIndex}
            onKeywordsChanged={onKeywordsChanged}
            onMatchIndexChanged={onMatchIndexChanged}
            onHideSearchbar={handleToggleSearchbar}
          />
        </div>
      )}
    </>
  );
};

export default DocumentToolbar;
